body {
  min-width: 720px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rv-discrete-color-legend-item {
	padding: 6px 12px !important;
	padding-top: 3px !important;
}

.rv-xy-plot  .rv-crosshair .rv-crosshair__inner--left {
  right: -10px;
}

.rv-xy-plot  .rv-crosshair .rv-crosshair__inner--right {
  left: -10px;
}